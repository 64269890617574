<template>
	<div class="postal-code-input">
		<v-autocomplete
			:id="addrId"
			:items="postalAddressList"
			:menu-props="{ bottom: true, offsetY: true }"
			outlined
			hide-details
			clearable
			@click:clear="(postalAddressList = []), (pageLoading = false), (searchvalue = null)"
			:search-input.sync="searching"
			class="filterSelect mt-2"
			item-text="description"
			return-object
			placeholder="Search address"
			@keyup="(e) => searchAddress(e, e.target.value)"
			@change="setValues"
			v-model="searchvalue"
		>
			<template #item="{ item }">
				<div
					class="fw-500 my-1 text-truncate text-uppercase"
					:style="{ maxWidth: 'max-content', width: 'max-content' }"
					@click="
						$emit('update:address', item), (searchvalue = null), (addrId = `postal-id-${+new Date()}`)
					"
				>
					{{ item.description }}
				</div>
			</template>
			<template #no-data>
				<div class="fw-500 ml-3 text-muted">
					<p v-if="pageLoading">
						Searching... for <span class="black--text">{{ searching }}</span>
					</p>
					<div v-else>
						<span v-if="postalAddressList.length == 0">No results for your search</span>
						&nbsp;
						<span
							class="my-1 text-truncate text-uppercase"
							:style="{ maxWidth: 'max-content', width: 'max-content' }"
						>
							{{ searching }}
						</span>
					</div>
				</div>
			</template>
		</v-autocomplete>

		<!-- <v-text-field
			:id="postalCodeId"
			right
			class="mt-2"
			ref="search"
			hide-details
			placeholder="Search address"
			outlined
			@keyup="(e) => searchAddress(e, e.target.value)"
		>
			<template #prepend-inner>
				<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
			</template>
		</v-text-field> -->

		<!-- <v-text-field
			v-bind="{
				...$attrs,
			}"
			:id="postalCodeId"
			append-icon="search"
			:append-inner-icon="pageloading ? 'mdi-spin mdi-loading' : ''"
			@keyup="(e) => searchAddress(e, e.target.value)"
			label="Search Address"
			solo
			ref="searchinput"
			hide-spin-buttons
			flat
			color="cyan"
			style="border: 1px solid #9e9e9e;padding:10px;min-height:inherit;"
			class="p-0"
			>
			</v-text-field> -->

		<!-- <v-menu
			max-width="300"
			v-model="searchMenu"
			v-if="postalAddressList.length > 1"
			max-height="400"
			offset-y
			ref="searchmenu"
			left
			:activator="`#${postalCodeId}`"
		>
			<v-list>
				<v-list-item
					v-for="(item, index) in postalAddressList"
					:key="index"
					:value="index"
					class="border-b"
					@click="setAddress(item)"
				>
					<template v-slot:prepend>
						<v-icon size="24" class="me-3" :icon="'mdi-map'"></v-icon>
					</template>
					<v-list-item-title class="white-space-pre-wrap">{{ item.description }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu> -->
	</div>
</template>
<script>
import { QUERY } from "@/core/services/store/request.module";
import { lowerCase } from "lodash";
// import ValidationMixin from "@/core/plugins/validation-mixin";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	mixins: [MainMixin],
	name: "pending-line-item",
	props: {
		searchingAddress: {
			type: Boolean,
			default: false,
		},
		country: {
			type: String,
			default: null,
		},
		/* id: {
			type: String,
			default: "postal-menu-activator",
		}, */
		countryCode: {
			type: String,
			default: null,
		},
	},
	data: () => {
		return {
			pageLoading: false,
			timeoutLimit: 500,
			timeout: null,
			searchMenu: false,
			postalAddressList: [],
			postalCodeId: "postal-id-" + +new Date(),
			searching: null,
			addrId: `postal-id-${+new Date()}`,
			searchvalue: null,
		};
	},

	// watch:{
	// 	resetAddress: {
	// 		immediate: true,
	// 		handler(param) {
	// 			console.log(param);
	// 			if(param){
	// 				this.searchingAddress = null;
	// 			}
	// 		},
	// 	},
	// },
	methods: {
		setAddress(address) {
			this.searchMenu = false;
			this.$emit("update:address", address);
		},
		setValues(evt) {
			console.log({ evt });
		},
		searchAddress(event, number) {
			this.pageLoading = true;
			if (!number) {
				this.postalAddressList = [];
			}

			if (
				event &&
				(event.key == "ArrowUp" ||
					event.key == "ArrowDown" ||
					event.key == "ArrowLeft" ||
					event.key == "ArrowRight" ||
					event.key == "Enter")
			) {
				return false;
			}

			if (number && number.toString().length > 1) {
				let _this = this;
				let url = "";
				let Request = {};
				/*  if (number && number.length < 6 && this.country == "singapore") {  }else{
		return false;
	  }  */
				clearTimeout(_this.timeout);
				_this.timeout = setTimeout(function () {
					if (_this.country == "singapore") {
						url = "https://micro.businessthrust.com/api/onemap-api/search";
						Request = {
							search: number,
						};
					} else {
						url = "https://micro.businessthrust.com/api/google-api/search";
						Request = {
							search: number,
							country: _this.countryCode ? _this.countryCode : "in",
						};
					}

					_this.$store
						.dispatch(QUERY, {
							url: url,
							data: Request,
						})
						.then((data) => {
							if (!data || (Array.isArray(data) && data.length == 0)) {
								_this.pageLoading = false;
							}
							if (data && data.length) {
								_this.searchMenu = true;
								if (lowerCase(_this.countryCode) != "sg") {
									_this.postalAddressList = data.map((row) => {
										const _address_2 = row.description;
										return {
											address1: _address_2,
											address2: "",
											unit_number: "",
											postal_code: "",
											description: row.description,
										};
									});
								} else {
									_this.postalAddressList = data.map((row) => {
										const _unit_number = "";
										const _address_1 = row.road_name;
										const _address_2 = row.building;
										const _postal_code = row.postal_code != "NIL" ? row.postal_code : "";
										const longitude = row.longitude != "NIL" ? row.longitude : "";
										const latitude = row.latitude != "NIL" ? row.latitude : "";

										return {
											address1: _address_1,
											address2: _address_2,
											unit_number: _unit_number,
											longitude: longitude,
											latitude: latitude,
											postal_code: _postal_code,
											description: row?.address,
										};
									});
								}
								_this.pageLoading = false;
							} else {
								_this.searchMenu = false;
								_this.pageLoading = false;
							}

							if (_this.postalAddressList && _this.postalAddressList.length == 1) {
								_this.setAddress(_this.postalAddressList[0]);
							}
							_this.pageLoading = false;
							/*_this.$nextTick(() => {
                _this.$refs["searchinput"]?.$refs['input']?.blur()
              })*/
						})
						.catch((error) => {
							_this.logError(error);
						})
						.finally(() => {
							_this.pageLoading = false;
						});
				}, _this.timeoutLimit);
			}
		},
	},
};
</script>
<style scoped></style>
