<template>
	<Dialog :dialog="CreateLeadDialog" :dialog-width="1200" @close="$emit('close', true)">
		<template v-slot:title>Create Lead</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="update_or_create"
			>
				<v-row class="mb-3">
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Status</label>
						</v-col>
						<v-col md="8" class="py-0 pr-10 position-relative">
							<v-autocomplete
								hide-details
								:items="status_List"
								placeholder="Status"
								outlined
								v-model="create_lead.status"
								item-text="text"
								item-value="value"
							>
								<template #prepend-inner>
									<div
										:style="{
											borderRadius: '50%',
											height: '15px',
											width: '15px',
											backgroundColor: colorsLeads[create_lead.status] || 'black',
										}"
									></div>
								</template>
								<template #item="{ item }">
									<div
										class="mr-3"
										:style="{
											borderRadius: '50%',
											height: '15px',
											width: '15px',
											backgroundColor: colorsLeads[item.value],
										}"
									></div>
									<span class="fw-500 py-1">
										{{ item.text }}
									</span>
								</template>
							</v-autocomplete>
						</v-col>
					</v-col>
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Source</label>
						</v-col>
						<v-col md="8" class="py-0 pr-10 position-relative">
							<v-autocomplete
								hide-details
								:items="sourceList"
								placeholder="Source"
								outlined
								v-model="create_lead.source"
								item-text="text"
								item-value="value"
								class=""
							>
							</v-autocomplete>
							<ManageAutocomplete title="Source" typeId="2" v-on:update="getSettings"></ManageAutocomplete>
						</v-col>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label">Opportunity</label>
						</v-col>
						<v-col md="8" class="py-0">
							<v-radio-group row hide-details class="mt-0" v-model="create_lead.opportunity">
								<v-radio
									style="min-width: 117px !important"
									label="High Chance"
									value="high-chance"
								></v-radio>
								<v-radio style="min-width: 117px !important" label="50 50" value="50-50"></v-radio>
								<v-radio
									style="min-width: 117px !important"
									label="Low Chance"
									value="low-chance"
								></v-radio>
								<v-radio style="min-width: 117px !important" label="None" value="none"></v-radio>
							</v-radio-group>
						</v-col>
					</v-col>
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Product Type</label>
						</v-col>
						<v-col md="8" class="py-0 pr-10 position-relative">
							<v-autocomplete
								hide-details
								:items="productList"
								placeholder="Product Type"
								outlined
								v-model="create_lead.product_type"
								item-text="text"
								item-value="value"
								class=""
							>
							</v-autocomplete>
							<ManageAutocomplete
								title="Product Type"
								typeId="4"
								v-on:update="getSettings"
							></ManageAutocomplete>
						</v-col>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Assign</label>
						</v-col>
						<v-col md="8" class="py-0 pr-10 position-relative">
							<v-autocomplete
								:items="userList"
								v-model="create_lead.assign"
								item-text="first_name"
								item-value="id"
								depressed
								placeholder="Assigned To"
								hide-details
								outlined
								hide-selected
								class="cuatom-drop"
								color="blue darken-4"
								small-chips
								:readonly="
									!AllowAnyOforGetRoleNType(['master', 'admin', 'manager']) &&
									current_user.users_type != 'accounts'
								"
								:rules="[vrules.required(create_lead.assign, 'Assign')]"
								deletable-chips
								:class="{
									required: !create_lead.assign,
								}"
							>
								<template v-slot:selection="data">
									<v-chip
										small
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="remove(data.selected)"
									>
										<v-avatar left>
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>

										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</v-col>
					</v-col>
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Lead Date</label>
						</v-col>
						<v-col md="8" class="py-0">
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								hideTopMargin
								id="Lead-date"
								placeholder="Lead Date"
								v-model="create_lead.lead_date"
							></DatePicker>
						</v-col>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="6 d-flex" v-if="false">
						<v-col md="4" class="py-0">
							<label class="input-label required">Customer Name</label>
						</v-col>
						<v-col md="8" class="py-0">
							<v-text-field
								hide-details
								outlined
								placeholder="Customer Name"
								v-model="create_lead.customer_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(create_lead.customer_name, 'Customer Name')]"
								:class="{
									required: !create_lead.customer_name,
								}"
								v-on:keyup="appendContactdetail()"
							>
							</v-text-field>
						</v-col>
					</v-col>
					<v-col md="6 d-flex">
						<v-col md="4" class="py-0">
							<label class="input-label required">Company Name</label>
						</v-col>
						<v-col md="8" class="py-0">
							<v-text-field
								hide-details
								outlined
								placeholder="Company Name"
								v-model="create_lead.company_name"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(create_lead.company_name, 'Company Name')]"
								:class="{
									required: !create_lead.company_name,
								}"
							>
							</v-text-field>
						</v-col>
					</v-col>
				</v-row>
				<!-- <v-row class="mb-3">
				   <v-col md="6 d-flex">
                        <v-col md="4" class="py-0">
                            <label class="input-label">Phone(Main)</label>
                        </v-col>
                        <v-col md="8" class="py-0">
                            <PhoneTemplate
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                id="phone_number"
                                v-model.number="create_lead.phone"
                                v-on:change="appendContactdetail()"
                            ></PhoneTemplate>
                        </v-col>
                   </v-col>
                   <v-col md="6 d-flex">
                        <v-col md="4" class="py-0">
                            <label class="input-label">Email(Main)</label>
                        </v-col>
                        <v-col md="8" class="py-0">
                            <v-text-field
                                hide-details
                                outlined
                                placeholder="Email"
                                v-model="create_lead.email"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-on:keyup="appendContactdetail()"
                            >
                            </v-text-field>
                        </v-col>
                    </v-col>
				</v-row> -->
				<v-col cols="12">
					<div class="form-grp-title d-flex">
						<div>Contact Person</div>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-4" class="white--text" depressed tile @click="add_person()">
							<v-icon left>mdi-plus</v-icon>
							Add
						</v-btn>
					</div>
					<v-row class="pl-4 pr-10">
						<v-col cols="1">
							<label class="fw-500">Primary</label>
						</v-col>
						<v-col cols="3">
							<label class="fw-500 required">Contact Name</label>
						</v-col>
						<v-col cols="3">
							<label class="fw-500">Email Address</label>
						</v-col>
						<v-col cols="3">
							<label class="fw-500">Phone No</label>
						</v-col>
					</v-row>
					<v-row
						class="pl-4 pr-10 position-relative"
						v-for="(data, index) in create_lead.contact_person"
						:key="index"
					>
						<v-col cols="1">
							<v-checkbox
								v-model="create_lead.contact_person[index].primary"
								hide-details
								style="margin-top: 0px !important"
								:readonly="create_lead.contact_person[index].primary == true ? true : false"
								v-on:change="checkBoxtype(index)"
							></v-checkbox>
						</v-col>
						<v-col cols="3">
							<v-text-field
								type="text"
								placeholder="Contact Name"
								v-model="create_lead.contact_person[index].contact_name"
								:rules="[vrules.required(create_lead.contact_person[index].contact_name, 'Contact Name')]"
								:class="{
									required: !create_lead.contact_person[index].contact_name,
								}"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</v-col>
						<v-col cols="3">
							<v-text-field
								type="email"
								placeholder="Email Address"
								v-model="create_lead.contact_person[index].email_address"
								:rules="[
									vrules.validEmail(create_lead.contact_person[index].email_address, 'Email Address'),
								]"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</v-col>
						<v-col cols="3">
							<PhoneTemplate
								:disabled="pageLoading"
								id="phone_number_Contact"
								class-name="mt-0"
								v-model="create_lead.contact_person[index].phone_number"
								:loading="pageLoading"
								@loading="pageLoading = $event"
								@blur="validatePhone(index)"
								@validity="
									($event) => {
										if (!$event) {
											create_lead.contact_person[index].phone_number = null;
										}
									}
								"
							></PhoneTemplate>
						</v-col>
						<v-col cols="2" class="d-flex">
							<!-- index != 0 -->
							<v-icon
								v-if="create_lead.contact_person[index].primary == 0"
								color="red"
								style="position: absolute; top: 6px; right: 16px"
								@click="delete_contact(create_lead.contact_person[index].id, index)"
								>mdi-delete</v-icon
							>
						</v-col>
						<!-- <template v-else>
								<v-icon
									v-if="index != 0"
									color="red"
									style="position: absolute; top: 6px; right: 16px"
									@click="delete_person(index)"
									>mdi-delete</v-icon
								>
							</template> -->
					</v-row>
				</v-col>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="update_or_create()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import { GET, DELETE, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import PhoneTemplate from "@/view/components/Phone";
import DatePicker from "@/view/components/DatePicker";
import ManageAutocomplete from "@/view/pages/leads/components/ManageAutocomplete";
import { getCurrentUser } from "@/core/services/jwt.service";
import { map } from "lodash";
import objectPath from "object-path";

export default {
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		CreateLeadDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		internal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			statusList: [],
			sourceList: [],
			opportunityList: [],
			productList: [],
			userList: [],
			countryList: [],
			create_lead: {
				status: null,
				source: "web-enquiry",
				opportunity: null,
				product_type: "seo",
				assign: null,
				customer_name: null,
				company_name: null,
				email: null,
				phone: null,
				amount: 0,
				lead_date: new Date().toISOString().substr(0, 10),
				user: 1,
				contact_person: [
					{
						id: 0,
						contact_name: null,
						email_address: null,
						phone_number: null,
						primary: true,
					},
				],
			},
			colorsLeads: {
				"not-contacted": "#808080",
				"follow-up": "#ffa500",
				meeting: "#2196f3",
				quotation: "#e91e63",
				reject: "#ff0000",
				accept: "#008000",
			},
			current_user: null,
		};
	},
	watch: {
		/* updatedData: {
			deep: true,
			immediate: true,
			handler(param) {
				this.init(param);
			},
		}, */
	},
	components: {
		Dialog,
		PhoneTemplate,
		DatePicker,
		ManageAutocomplete,
	},

	methods: {
		remove(item) {
			if (this.AllowAnyOforGetRoleNType(["master", "admin", "manager", "super_admin"])) {
				console.log(item);
				this.create_lead.assign = null;
			}
		},
		validatePhone(index) {
			let primary_phone = this.create_lead.contact_person[index].phone_number;
			let phoneArr = this.create_lead.contact_person.filter((ph) => ph.phone_number == primary_phone);
			if (phoneArr.length > 1) {
				this.$store.commit(SET_ERROR, [
					{
						model: true,
						message: `Contact Phone Number ${primary_phone} already exists`,
					},
				]);
				this.formValid = false;
				return false;
			}
			this.formValid = true;
		},
		checkBoxtype(index) {
			let falseData = map(this.create_lead.contact_person, (row, rowIndex) => {
				if (rowIndex != index) {
					row.primary = false;
				}
				return row;
			});
			this.create_lead.contact_person = falseData;
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["extendCheckoutForm"]);

			this.$refs["extendCheckoutForm"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["extendCheckoutForm"].validate()) {
				return false;
			}

			if (this.create_lead.contact_person.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please add atleast one contact person." },
				]);
				return false;
			}

			if (this.create_lead.lead_date == null) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Please Add Lead Date" }]);
				return false;
			}

			if (this.create_lead.assign == 0) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Please add atleast one Assignee." }]);
				return false;
			}
			this.pageLoading = true;
			let formData = new FormData();

			formData.append("status", this.create_lead.status ? this.create_lead.status : "");
			formData.append("source", this.create_lead.source ? this.create_lead.source : "web-enquiry");
			formData.append(
				"opportunity",
				this.create_lead.opportunity ? this.create_lead.opportunity : "" //high-chance
			);
			formData.append(
				"product_type",
				this.create_lead.product_type ? this.create_lead.product_type : "crm"
			);
			formData.append("amount", this.create_lead.amount ? this.create_lead.amount : 0);
			formData.append("assign", this.create_lead.assign ? this.create_lead.assign : 1);
			formData.append(
				"customer_name",
				this.create_lead.customer_name ? this.create_lead.customer_name : null
			);
			formData.append(
				"company_name",
				this.create_lead.company_name ? this.create_lead.company_name : null
			);

			if (this.create_lead && this.create_lead.lead_date) {
				formData.append("lead_date", this.create_lead.lead_date ? this.create_lead.lead_date : null);
			}

			formData.append("user", this.create_lead.user ? this.create_lead.user : null);

			for (let i = 0; i < this.create_lead.contact_person.length; i++) {
				formData.append(
					`contact_person[${i}][primary]`,
					this.create_lead.contact_person[i].primary ? this.create_lead.contact_person[i].primary : false
				);
				formData.append(
					`contact_person[${i}][id]`,
					this.create_lead.contact_person[i].id ? this.create_lead.contact_person[i].id : 0
				);
				formData.append(
					`contact_person[${i}][contact_name]`,
					this.create_lead.contact_person[i].contact_name
				);
				if (
					this.create_lead &&
					this.create_lead.contact_person &&
					this.create_lead.contact_person[i] &&
					this.create_lead.contact_person[i].email_address
				) {
					formData.append(
						`contact_person[${i}][email_address]`,
						this.create_lead.contact_person[i].email_address
					);
				}
				if (
					this.create_lead &&
					this.create_lead.contact_person &&
					this.create_lead.contact_person[i] &&
					this.create_lead.contact_person[i].phone_number
				) {
					formData.append(
						`contact_person[${i}][phone_number]`,
						this.create_lead.contact_person[i].phone_number
					);
				}
			}

			let requestTYPE = POST;
			let requestURL = "lead";

			if (this.leadId) {
				requestURL = `lead/${this.leadId}`;
			}
			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.leadId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Lead updated successfully." },
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Lead added successfully." },
						]);
					}
					this.$emit("refresh", true);

					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		add_person() {
			this.create_lead.contact_person.push({
				contact_name: null,
				email_address: null,
				phone_number: null,
			});
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					_this.statusList = data.status;
					_this.sourceList = data.source;
					_this.opportunityList = data.opportunity;
					_this.productList = data.product_type;
					_this.countryList = data.country;
					_this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},

		appendContactdetail() {
			this.create_lead.contact_person[0].contact_name = this.create_lead.customer_name;
			this.create_lead.contact_person[0].email_address = this.create_lead.email;
			this.create_lead.contact_person[0].phone_number = this.create_lead.phone;
		},
		delete_contact(id, index) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-contact/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contact deleted successfully.",
							},
						]);
						this.getLead();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.create_lead.contact_person.splice(index, 1);
			}
		},
	},

	computed: {
		...mapGetters(["errors"]),
		status_List: {
			get() {
				let _status = [...this.statusList];
				let idx = _status.findIndex((v) => v.value == "all");
				_status.splice(idx, 1);
				return _status;
			},
		},
	},
	mounted() {
		this.getSettings();
		const CURRENT_USER = getCurrentUser();
		this.current_user = CURRENT_USER;
		this.create_lead.assign = objectPath.get(CURRENT_USER, "id");
	},
};
</script>
